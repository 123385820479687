.alert-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.alert-wrapper {
  margin: 0.5rem 0;
}

.alert-header {
  text-align: center;
  color: #fff;
}

.alert-wrapper > div > p {
  color: #fff;
}

.alert-header svg {
  margin-right: 0.5rem;
}

.width-height-100 {
  width: 100%;
  height: 100%;
}

.height-100vh {
  min-height: 100vh;
}

.logo {
  width: 12rem;
  height: auto;
  margin-bottom: 1rem;
}
