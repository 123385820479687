// This file can be used to override Bootstrap's default variables, found in _variables.scss
// The stylelint rule override in package.json for no-invalid-position-at-import-rule is to
// accommodate this file because the sass overrides need to be before the bootstrap imports.

$endeavor: #285384;
$dark-endeavor: #1c3b5e;
$lochmara: #346cab;
$whisper: #ececec;
$deep-saffron: #ff9233;
$mojo: #cc5b40;
$alto: #d3d3d3;
$persian-red: #d1322b;
$dark-persian-red: #97231e;
$salem: #0a752c;
$sunflower: #e5c11e;
$dove-gray: #656565;
$rsi-black: #343a40;

// Custom RSI variables
:root {
  --persian-red: #{$persian-red};
  --deep-saffron: #{$deep-saffron};
  --dark-persian-red: #{$dark-persian-red};
  --salem: #{$salem};
  --lochmara: #{$lochmara};
  --endeavor: #{$endeavor};
  --dark-endeavor: #{$dark-endeavor};
  --whisper: #{$whisper};
  --alto: #{$alto};
  --dove-gray: #{$dove-gray};
  --bootstrap-border-gray: #ced4da;
  --rsi-light-gray: #f0f0f0;
  --rsi-medium-gray: #ddd;
  --rsi-dark-gray: #ccc;
  --rsi-extra-dark-gray: #777;
  --rsi-black: #{$rsi-black};
  --sunflower: #{$sunflower};
  --grid-cell-line-height: 1.5em;
  --grid-cell-padding: 0.375em;

  // 2.25em is used to mirror the height of bootstrap's form-control class
  --virtual-item-height: 2.25em;

  font-size: 0.875rem;
}

// RSI style guide theme overrides
$primary: $endeavor;
$secondary: $dove-gray;
$info: $lochmara;
$success: $salem;
$danger: $persian-red;
$light: $whisper;

// Default font overrides
@font-face {
  src: url('./fonts/opensans-regular-webfont.woff2') format('woff2');
  font-family: 'Open Sans';
  font-weight: normal;
  font-style: normal;
}

@font-face {
  src: url('./fonts/opensans-semibold-webfont.woff2') format('woff2');
  font-family: 'Open Sans';
  font-weight: bold;
  font-style: normal;
}

$font-family-sans-serif: 'Open Sans', sans-serif;
$body-color: $rsi-black;
$close-color: $rsi-black;

// Use rem based breakpoints, direct px conversion
$grid-breakpoints: (
  xs: 0,
  sm: 36rem,
  md: 48rem,
  lg: 62rem,
  xl: 75rem,
);

// Disable alert color transformations
$alert-bg-scale: 0;
$alert-border-scale: 0;

// Button overrides
$btn-padding-x: 0.5rem;
$btn-padding-y: 0.25rem;

// Override nav pills active color
$nav-pills-link-active-color: #fff;
$nav-pills-link-active-bg: $lochmara;

// Override header sizes
$h1-font-size: 1rem * 1.25;
$h2-font-size: 1rem;
$h3-font-size: 1rem;
$h4-font-size: 1rem;
$h5-font-size: 1rem;
$h6-font-size: 1rem;

// Override heading line height
$headings-line-height: 2;

// Override unwanted margin bottoms
$paragraph-margin-bottom: 0;
$headings-margin-bottom: 0;
$hr-margin-y: 0;
$alert-margin-bottom: 0;
$label-margin-bottom: 0;

// Grid table overrides
$table-cell-padding: 4px;
$table-bg: #fff;
$table-border-color: #ccc;
$table-striped-order: even;

// Disable Modal animations
$modal-scale-transform: none;
$modal-fade-transform: none;
$modal-transition: none;

// Form overrides
$input-height: unset;
$input-font-size: 1em;
$input-padding-x: 0.5rem;
$input-padding-y: 0.25rem;

// Card overrides
$card-spacer-y: 0.5rem;
$card-spacer-x: 0.5rem;
$card-border-width: 0;
$card-border-radius: 0;
$card-border-color: $endeavor;
$card-inner-border-radius: 0;
$card-cap-bg: $endeavor;
$card-cap-color: #fff;

// Custom form overrides
$form-select-bg-size: 1.25em 1em;
$form-select-indicator-color: $dove-gray;

// The form-select SVG indicator is Font-awesome's chevron down
$form-select-indicator: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#{$form-select-indicator-color}" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"/></svg>');
$form-select-indicator-padding: 1.5rem;
$form-select-padding-x: 0.5rem;
$form-select-padding-y: 0.25rem;

// Required
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/variables-dark';
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';

// Optional - must mirror the order in bootstrap.scss
@import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/type';
@import '../node_modules/bootstrap/scss/tables';
@import '../node_modules/bootstrap/scss/forms';
@import '../node_modules/bootstrap/scss/buttons';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/dropdown';
@import '../node_modules/bootstrap/scss/button-group';
@import '../node_modules/bootstrap/scss/nav';
@import '../node_modules/bootstrap/scss/card';
@import '../node_modules/bootstrap/scss/badge';
@import '../node_modules/bootstrap/scss/alert';
@import '../node_modules/bootstrap/scss/progress';
@import '../node_modules/bootstrap/scss/list-group';
@import '../node_modules/bootstrap/scss/close';
@import '../node_modules/bootstrap/scss/modal';
@import '../node_modules/bootstrap/scss/popover';
@import '../node_modules/bootstrap/scss/helpers/visually-hidden';
@import '../node_modules/bootstrap/scss/utilities';
@import '../node_modules/bootstrap/scss/utilities/api';

body {
  overflow: hidden;
}

// Remove default browser button styling
button {
  background: none;
  border: 0;
  padding: 0;
}

// Bootstrap reboot override styles
ul {
  margin: 0;
  padding: 0;
}

address {
  margin: 0;
}

.card {
  box-shadow: 0 2px 3px 0 rgba(0 0 0 / 25%);
}

.card > ul {
  border: 1px solid $endeavor !important;
  border-top: 0;
}

.card-header {
  padding: 0 0.5rem;
}

.card-header .btn {
  padding: 0 0.5rem;
  line-height: 2rem;
  border: 0;
  border-radius: 0;
}

.card-body {
  border: 1px solid $endeavor;
}

.card-header + .card-body {
  border-top: 0;
}

// Additional form styles
.form-control {
  // Fixes quick search border issue in chrome
  background-clip: unset;
}

.alert-danger {
  background-color: var(--persian-red);
}

.alert-warning {
  background-color: var(--sunflower);
}

// Regenerate Bootstrap's buttons to add a
// border color that is slightly darker
/* stylelint-disable-next-line */
@each $color, $value in $theme-colors {
  .btn-bordered-#{$color} {
    // In Bootstrap's _buttons.scss, the second argument
    // is the same as the first ($value), so the border
    // color is effectively the same. Darken the border
    // color here by 10%, mainly done for button groups.
    /* stylelint-disable-next-line */
    @include button-variant($value, darken($value, 10%));
  }
}

/*
  This is needed to correctly layer the modal
  backdrop and modal dialog when react-bootstrap modals are
  rendered on top of each other (react-bootstrap doesn't support nested modals). 
  We currently have a need for this in multiple places (e.g. the resubmit EDI confirmation modal 
  from the action in the EDI history grid on the shipment history tab
  of the shipment form, shipment accrual recalculation, editing client roles in 
  the client form, etc.)
  
  Approaches that attempt to target the nth-of-class (like https://stackoverflow.com/a/62870548/6121634)
  do not work because there is no way to target nth-of-class in CSS. A solution like 
  https://stackoverflow.com/a/69704736 could work but would be fragile as there's no guarantee that these modals 
  will always be rendered  contiguously at the end of the body 
*/
div[role='dialog'][aria-modal='true'] {
  z-index: 1050;
}

.dropdown-toggle::after {
  transition: transform 0.25s linear;
}

.dropdown-toggle[aria-expanded='true']::after {
  transform: rotate(180deg);
}

/*
  These rules are used to vertically center the icons
  and were copied from FontAwesome's source code.
*/
.react-icons-icon {
  overflow: visible;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  width: 1.25em;
  text-align: center;
  vertical-align: -0.125em;
}
