.spinner-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.full-screen {
  height: 100vh;
  width: 100vw;
}

.overlay {
  /*
    99 arbitrarily chosen so it is always
    on top of anything else in the grid
    or any other content for other use cases
  */
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(255 255 255 / 50%);
}

.spinner {
  display: inline-block;
  position: relative;
  width: 4rem;
  height: 4rem;
}

.spinner.table-row {
  width: 1.5rem;
  height: 1.5rem;
}

.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 3.1875rem;
  height: 3.1875rem;
  margin: 0.375rem;
  border: 0.375rem solid var(--lochmara);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--lochmara) transparent transparent transparent;
}

.spinner.table-row div {
  width: 1.1875rem;
  height: 1.225rem;
  margin: 0.225rem;
  border-width: 0.175rem;
}

.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}

.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}

.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.logo {
  width: 12rem;
  height: auto;
  margin-bottom: 1rem;
}
